<!--
    http://localhost:4200/store/artamis/validate/ac97700878eb995cbad18f45c6c6f7543770f208/test1@karibou.ch
-->
<div *ngIf="!user.isReady()" class="mail-checker">
    <!-- FIXME -->
    <p class="" >
        <!-- FIXME {{user.getEmailStatus()|date}}. -->
        {{i18n[locale].title_h2}} <br/>
        {{i18n[locale].action_1}}
        <a href="javascript:;" (click)="sendConfirmationMail()">{{sendConfirmation ? i18n[locale].action_2_done : i18n[locale].action_2}}</a>

    </p>
</div>

