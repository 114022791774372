<form novalidate
      (ngSubmit)="onPayment()"
      [formGroup]="stripe"
      [class.working]="isLoading"
      class="stripe-component">
  <h4 [innerHtml]="title" [hidden]="!title"></h4>
  <div class="groupfield fullwidth">
    <input  id="f001" class="form__field" type="text" formControlName="name" [ngModel]="user.display()"  [placeholder]="label.user_display_name">
    <label class="form__field" for="f001" class="form__label" [class.error]="isInvalid('name')">
        {{label.user_display_name}}
    </label>
  </div>


  <div id="card-element" class="field"></div>
  <button type="submit" [disabled]="isLoading||!isValid" class="save default">
    <span class="material-symbols-outlined">
      credit_card
    </span>
    {{$i18n[locale].action_check}}
  </button>

  <div [hidden]="!displayCardError" class="stripe-error">
    <h4>{{label.stripe_issue}}</h4>
    {{displayCardError}}
  </div>
</form>
